import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import OurServices from "../components/Index/OurServices"
import TeamMember from "../components/Index/TeamMember"
import RecentProjects from "../components/Index/RecentProjects"
import MainBanner from "../components/DataAnalyticsAIStartup/MainBanner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import FeaturedService from "../components/DataAnalyticsAIStartup/FeaturedService"
import { Helmet } from "react-helmet"
import TagsForAll from "../components/Common/TagsForAll"
import designRushBlue from "../assets/images/main-banner/DesignRush.png"
import designRushBlack from "../assets/images/main-banner/DesignRush-black.png"

const Home = () => {

  return (
    <Layout>
      <TagsForAll pageTitle="Unparalleled Innovative solution for your IT concerns only @ Zaavia" />
      <Helmet>
        <meta name='robots' content='index, follow' />
        <link rel="canonical" href="https://zaavia.net/" />
        <meta name="google-site-verification" description="803b7d20a6e36900b6924b2fc492f348" />
        <a href="https://www.softwaresuggest.com/zunbeel/write-review" target="_blank"><img src="http://www.softwaresuggest.com/assets2/img/review_new.svg" width="250"/></a>
        <meta name="p:domain_verify" description="6q3QBZwMaQADGfTyK_BF78y9gyGKSKawdG2yJ5lZV60" />
        <meta name="description" content="Unleash EFFICIENCY & INNOVATION with our cutting-edge productivity tools-Tailor-made IT solutions to drive your success all the way!" />
      </Helmet>
      <Navbar />
      <MainBanner />
      <FeaturedService />
      <RecentProjects />
      <OurServices />
      <ProjectStartArea />
      <TeamMember />
      <Footer designRushBlue={designRushBlue} designRushBlack={designRushBlack} />
    </Layout>
  )
}
export default Home