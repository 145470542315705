import React from 'react'
import pic1 from '../../assets/images/icons/pic1.png'
import pic2 from '../../assets/images/icons/pic2.png'
import pic3 from '../../assets/images/icons/pic3.png'
import shape2 from '../../assets/images/services/service-shape2.png'

const FeaturedService = () => {
    return (
       
        <div className="boxes-area">
            <fieldset>
                <legend className="headerfield"> <h3>Our Expertise</h3> </legend>
                <div className="container">
                <div className="row">
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={pic1} alt="banner" />
                            </div>  
                            <h3>
                                
                                Cloud computing development
                                
                            </h3>
                            <p>Experts in highly scalable resilient solution over the cloud as well as on premises.</p>

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={pic2} alt="banner" />
                            </div>
                            <h3>
                                
                                     Hybrid mobile App development
                                
                            </h3>
                            <p> Hybrid mobile app development using Flutter, Ionic and React Native technologies.</p>

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={pic3} alt="banner" />
                            </div>
                            <h3>
                                 Requirements Engineering
                            </h3>
                            <p>Experts in Requirements gathering, Business analysis, Application prototyping and Software requirements specification</p>
                            
                            {/* <Link to="/service-details" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Read More <span></span>
                            </Link> */}

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
             </div>
            </fieldset>
        </div>
        
    )
}

export default FeaturedService