import React from 'react'
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    strapiServicesOne {
        simpleText
        
        helpText
        image {
          url
        }
        services {
          id
          icon{
            url
        }
          services
        }
    }
    strapiServicesTwo {
        simpleText
        header
        helpText
        image {
            url
        }
        services {
          id
          icon{
            url
        }
          services
        }
    }
  }
`

const OurServices = () => {
    const data = useStaticQuery(query)
    const {
        strapiServicesOne, strapiServicesTwo
    } = data
    console.log(data)
    console.log(strapiServicesOne?.image?.url,"serviessss")
    return (
        <React.Fragment>
            <fieldset>
            <legend style={{width:" 80%"}}><h3> Consulting Services</h3></legend>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="about-img">
                                {/* <img src={service1} alt="service" /> */}
                                <img src={strapiServicesOne?.image?.url} />
                                {/* <Image fluid={strapiServicesOne?.image?.url} /> */}
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            
                            <div className="about-content">
                                <div className="content">
                                    {/* <h2>{strapiServicesOne.header}</h2> */}
                                    {/* <p className='mb-0'>{strapiServicesOne.helpText}</p> */}
                                    <ul className="about-list mb-0">
                                        {strapiServicesOne.services.map(service => (
                                            <li key={service.id}>
                                                {console.log(service , "12345678")}
                                                <i><img src={service.icon?.url} alt="icon" /> </i>
                                                {service.services}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}
            {/* Service Right Image Style */}
            <legend style={{width:" 80%" ,     margin: "auto",marginBottom:'20px'}}>
            <h3>Application Development</h3></legend>
            
            <div className="our-mission-area pb-50">
                
                <div className="container-fluid">
                
                    <div className="row align-items-center">
                    
                        <div className="col-lg-7 col-md-12">
                            
                            <div className="our-mission-content">
                                
                                <div className="content">
                                    
                                    {/* <h2>{strapiServicesTwo.header}</h2> */}
                                    {/* <p>{strapiServicesTwo.helpText}</p> */}

                                    <ul className="our-mission-list mb-0">
                                        {strapiServicesTwo.services.map(service => (
                                            <li key={service.id}>
                                                <i><img src={service.icon?.url} alt="icon" /> </i>
                                                {service.services}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12">
                            <div className="our-mission-image">
                                {/* <Image fluid={strapiServicesTwo.image.url} /> */}
                                <img src={strapiServicesTwo?.image?.url} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
            </fieldset>
        </React.Fragment>
    )
}

export default OurServices;